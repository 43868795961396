<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Удаление информационной части</template>
        <template #content>
          <TabView>
            <TabPanel header="Удаление">
              <ApplicationSearchForm
                ref="searchForm"
                :actions="createActions"
              />
            </TabPanel>
            <TabPanel header="Запросы на удаление ИЧ">
              <DeleteInformationPartsTable ref="requestsTable"/>
            </TabPanel>
            <TabPanel header="Список заявок с удаленной ИЧ">
              <DeleteInformationPartApplicationsTable ref="applicationsTable"/>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import DeleteInformationPartsTable from "@/components/tables/DeleteInformationPartsTable.vue";
import DeleteInformationPartApplicationsTable from "@/components/tables/DeleteInformationPartApplicationsTable.vue";
import InformationPartService from "@/service/InformationPartService";
import ResponseService from "@/service/ResponseService";
import ApplicationSearchForm from "@/components/Forms/ApplicationSearchForm.vue";

export default {
  name: 'DeleteInformationPart',
  components: {
    ApplicationSearchForm,
    DeleteInformationPartApplicationsTable,
    DeleteInformationPartsTable
  },
  methods: {
    createActions({ application, borrower, event }) {
      const items = [
        {
          label: 'Удалить ИЧ',
          icon: 'pi pi-trash',
          command: () => this.createDeleteRequest(application, borrower)
        }
      ];

      if (event !== null) {
        items.push({
          label: 'Перейти к событию',
          icon: 'pi pi-ticket',
          command: () => { window.open(`/event?eventId=${event.id}&eventDate=${event.date}&locale=${event.locale}`) }
        });
      }

      return items;
    },
    createDeleteRequest(application, borrower) {
      this.$confirm.require({
        message: this.getConfirmMessage(application),
        header: 'Подтвердите удаление',
        acceptLabel: 'Удалить',
        acceptIcon: 'pi pi-trash',
        rejectLabel: 'Отмена',
        rejectIcon: 'pi pi-ban',
        accept: () => {
          this.$refs.searchForm.setLoading(true);
          InformationPartService.delete(application.uuid, borrower.uuid, application.useOldFormat).then((res) => {
            if (res.error === null) {
              this.$toast.add({
                severity: 'success',
                summary: 'Удаление успешно',
                detail: res.feedback,
                life: 3000
              })
              this.$refs.searchForm.clear()
              this.$refs.requestsTable.refresh()
              this.$refs.applicationsTable.refresh()
              return;
            }

            this.$toast.add({
              severity: 'warn',
              summary: 'Ошибка создания запроса на удаление ИЧ',
              detail: res.error,
              life: 5000
            })
          })
          .catch((err) => {
            ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
          })
          .finally(() => {
            this.$refs.searchForm.setLoading(false);
          })
        }
      })
    },
    getConfirmMessage(application) {
      return `Удалить информационную часть для заявки с UUID "${application.uuid}"?`;
    }
  }
}
</script>


